import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Category, Props as CategoryProps } from './Category'

export interface Props {
  claim?: string
  categories: CategoryProps[]
}

export const GalleryCategories = memo(function GalleryCategories({
  claim,
  categories,
}: Props) {
  if (!categories) {
    return null
  }

  return (
    <Container>
      {claim ? <Claim>{claim}</Claim> : null}
      <Categories row wrap>
        {categories.map((item, index) => (
          <Category key={index} {...item} />
        ))}
      </Categories>
    </Container>
  )
})

const Container = styled.section`
  margin: 13.75rem auto 0;
`

const Claim = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.1875rem;
  margin-bottom: 8.125rem;
  text-align: center;
`

const Categories = styled(FlexBox)``
