import styled from '@emotion/styled'
import { ModalClose } from 'app/components/Common/Header/ModalClose'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Plus } from 'app/components/Icons'
import useLockedBody from 'app/utils/useLockedBody'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Gallery } from './Gallery'

export interface Props {
  images?: ImageProps[]
  languageCode: string
  thumbnail?: ImageProps
  title: string
}

export const Category = memo(function Category({
  images,
  languageCode,
  thumbnail,
  title,
}: Props) {
  if (!images) {
    return null
  }
  const [_locked, setLocked] = useLockedBody()
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <>
      {thumbnail ? (
        <Thumb
          onClick={() => {
            if (!modalGalleryStatus) {
              setModalGalleryStatus(true)
              if (window.innerWidth > 1023) {
                setLocked(true)
              } else {
                document.body.style.overflow = 'hidden'
              }
            } else {
              setModalGalleryStatus(false)
              if (window.innerWidth > 1023) {
                setLocked(false)
              } else {
                document.body.style.overflow = 'auto'
              }
            }

            setClickedSlide(0)
            setTimeout(() => {
              setClickedSlide(null)
            }, 100)
          }}
        >
          <LazyLoadComponent>
            <Image {...thumbnail} />
          </LazyLoadComponent>
          {title ? (
            <Wrap>
              <Title>{title}</Title>
              <Plus />
            </Wrap>
          ) : null}
        </Thumb>
      ) : null}

      {images ? (
        <Modal className={modalGalleryStatus ? ' open' : undefined}>
          <ModalClose
            languageCode={languageCode}
            onClick={() => {
              if (window.innerWidth > 1023) {
                setLocked(false)
              } else {
                document.body.style.overflow = 'auto'
              }
              setModalGalleryStatus(false)
              setClickedSlide(null)
            }}
            variant="gallery"
          />
          <Gallery
            category={title}
            clickedSlide={clickedSlide}
            images={images}
            isOpen={modalGalleryStatus}
          />
        </Modal>
      ) : null}
    </>
  )
})

const Thumb = styled.div`
  width: 50%;
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 37%;
  position: relative;

  @media (min-width: 1024px) {
    &:hover {
      &:before {
        opacity: 0.4;
      }
      img {
        transform: scale(1.1);
      }
      svg {
        opacity: 1;
      }
    }
  }

  @media (max-width: 1023px) {
    svg {
      opacity: 1;
    }
  }

  &:nth-of-type(8n + 3),
  &:nth-of-type(8n + 5) {
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: inset 0px 0px 0px 3.125rem
        ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out !important;
  }

  @media (max-width: 1023px) {
    &:nth-of-type(8n + 3),
    &:nth-of-type(8n + 5) {
      transform: none;

      &:after {
        box-shadow: inset 0px 0px 0px 1.875rem
          ${({ theme }) => theme.colors.variants.neutralLight4};
      }

      h2 {
        font-size: 1.875rem;
        line-height: 2.25rem;
      }
      svg {
        height: 1.25rem;
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 0.75rem;
    padding-bottom: 66%;
    &:first-of-type {
      margin-top: 0;
    }

    &:nth-of-type(8n + 3),
    &:nth-of-type(8n + 5) {
      &:after {
        content: none;
      }
    }
  }
`

const Wrap = styled.div`
  width: 100%;
  padding: 0 3.333vw;
  position: absolute;
  top: calc(50% + 1.25rem);
  left: 0;
  text-align: center;
  transform: translateY(-50%);
  z-index: 2;

  svg {
    width: auto;
    height: 1.25rem;
    margin-top: 0.75rem;
    opacity: 0;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 2;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    padding: 0 1.875rem;
  }
`

const Title = styled.h2`
  width: 100%;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.1875rem;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`
